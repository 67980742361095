import React from "react"
import Base from "../Base"
import "../styles/index.scss"
import graphsSafe from "../images/graphs.png"
import graphs from "../images/graphs.webp"
import networks from "../images/networks.webp"
import networksSafe from "../images/network.png"

const Home = () => (
  <Base title="Home">
    <header className="content-header">
      <h1>Low Latency Cryptocurrency Market Making</h1>
    </header>
    <main className="content-main">
      <section className="pure-g justify-content-center">
        <div className="pure-u-md-11-24">
          <img
            width="475"
            height="267"
            alt="Trading"
            className="pure-img trading-img"
            src={graphs}
            onError={(event) => event.target.setAttribute("src", graphsSafe)}
          />
        </div>
        <div className="pure-u-md-2-24" />
        <div className="pure-u-md-11-24 info top">
          <h2 className="text-center-md">
            <b>Proprietary Trading</b>
          </h2>
          <h3 className="text-center-md">
            We specialise in{" "}
            <b>liquidity providing strategies for cryptocurrency derivatives</b>
            . To navigate such a new market, we take a data driven approach to
            risk management and capital allocation.
          </h3>
        </div>
      </section>
      <section className="pure-g justify-content-center">
        <div className="pure-u-md-11-24 info">
          <h2 className="text-center-md">
            <b>Low Latency, High Throughput</b>
          </h2>
          <h3 className="text-center-md">
            We&rsquo;ve designed our trading infrastructure to be at the{" "}
            <b>cutting edge</b> of speed and reliability in the cryptocurrency
            space. Our latency advantage improves our fills and helps manage
            risk, even during high volatility.
          </h3>
        </div>
        <div className="pure-u-md-2-24" />
        <div className="pure-u-md-11-24">
          <img
            width="475"
            height="267"
            alt="World"
            className="pure-img trading-img"
            src={networks}
            onError={(event) => event.target.setAttribute("src", networksSafe)}
          />
        </div>
      </section>
      <div className="test">
        <h1 className="text-center">Built upon experience and knowledge</h1>
        <div className="pure-g justify-content-center">
          <div className="pure-u-md-1-3 text text-center">
            <h2>
              <b>Quality market data</b>
            </h2>
            <h3>
              We have over 2 years of first party market data, giving us a huge
              edge in our analysis.
            </h3>
          </div>
          <div className="pure-u-md-1-3 text text-center">
            <h2>
              <b>Low correlation</b>
            </h2>
            <h3>We strive for low correlation with the underlying assets.</h3>
          </div>
          <div className="pure-u-md-1-3 text text-center">
            <h2>
              <b>Low volatility</b>
            </h2>
            <h3>
              We aim for low volatility regardless of the performance of the
              underlying assets.
            </h3>
          </div>
        </div>
        <section>
          <h2 className="text-center">
            <b>A little about our director, Matthew Tweed</b>
          </h2>
          <p className="text-center paragraph">
            He&rsquo;s been investing and trading in crypto since 2015.
            Experience in both the micro structure and broad trends of crypto
            have enabled him to optimise the unique set of strategies needed for
            a relatively immature market.
          </p>
        </section>
      </div>
    </main>
  </Base>
)

export default Home
